.flex-container {
    display: flex;
    justify-content: space-evenly; /* Distributes space evenly between and around the items */
    gap: 20px; /* Provides space between the items */
    flex-wrap: wrap; /* Allows items to wrap in smaller screens */
    padding: 30px 50px; /* Adds padding to the left and right of the container */
    margin-left: 100px;
}

/* Optional: Set a max-width on child components to prevent them from stretching too wide */
.flex-container > div {
    max-width: calc(33% - 20px); /* Adjust based on your design needs */
    flex-grow: 1; /* Allows the items to grow to fill the space */
}