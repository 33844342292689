/* StorageEditPage.css or a specific CSS file */

.Storage-Dropdown-container {
    margin: 20px 0;
  }
  
.Storage-Dropdown-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    }

.Storage-Dropdown-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    }

/* StorageEditPage.css or a specific CSS file */

.Storage-Dropdown-card {
    background-color: #fff; /* Card background */
    border: 1px solid #ddd; /* Border for the card */
    border-radius: 8px; /* Rounded corners for the card */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Padding inside the card */
    margin: 20px 0; /* Margin around the card */
    width: 100%;
    max-width: 70%;
    margin-left: 5%;
  }
  
  /* Continue with other specific dropdown styles */
.Storage-Dropdown-container {
    margin-bottom: 20px; /* Adjust spacing within the card */
    }
  
  /* Other specific styles remain unchanged */
  
  