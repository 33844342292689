/* HeaderPacking.css */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.packing-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  text-align: left;
}

.detail-content-wrapper {
  display: flex;
  width: 60%; /* Set to the same width as packing-header-box */
  justify-content: 30px;
}

.packing-header-box {
  display: flex;
  justify-content: space-between; /* Space between text and image */
  align-items: center; /* Center items vertically */
  width: 55%;
  padding: 10px;
  padding-left: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(96, 109, 252);
  margin-bottom: 20px;
  background-color: #00bbff;
  border: 2px solid rgb(96, 109, 255);
  min-width: 400px;
}

.header-image {
  height: 200px; /* Image takes the full height of the container */
  border-radius: 5px;
  /* Removed align-items: right; as it is not a valid property for an image */
}

.detail-header-box, .packing-main-box {
  width: 50%; /* Each box will take up half of the detail-content-wrapper width */
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff; /* Set background color to white */
  align-self: center;
}

.detail-header-box {
  box-shadow: 0 0 10px green;
}

.packing-main-box {
  box-shadow: 0 0 10px rgb(96, 109, 252); /* Purple shadow */
}

.packing-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.user-greeting, .button-group {
  margin-bottom: 10px;
}

.button-group {
  display: flex; /* Ensures flexbox layout */
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-start; /* Aligns items to the start of the flex container */
}

.switch-button, .pack-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Space between buttons */
}

.switch-button {
  background-color: #0400ff;
  color: white;
}

.pack-button {
  background-color: #09ff42;
  color: black;
  margin-bottom: 10px;
}

.missing-button {
  margin-top: 35px;
  background-color: #ff4141; /* Bright red for missing button */
  color: white;
  margin-bottom: 10px;

}

/* If there's still an issue, consider adding this */
.button-group > button {
  flex: 0 0 auto; /* Prevents buttons from stretching */
}

.pouchtrack-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Use viewport height to cover the full page */
  padding: 20px;
  text-align: center;
  background-color: black;
  color: white;
}


.pouchtrack-page h1 {
  font-size: 5em;
  color: #ffffff; /* Example color */
  margin-bottom: 10px;
}

.pouchtrack-page p {
  color: #bab8b8; /* Example color */
  margin-bottom: 20px;
}

.pouchtrack-page button {
  padding: 10px 20px;
  border: 2px solid #09ff42; /* Example border color */
  border-radius: 5px;
  background-color: #000000; /* White background */
  color: #333; /* Example text color */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pouchtrack-page button:hover {
  background-color: #09ff42; /* Change color on hover */
  color: #050505;
}
.landing-page-header-image {
  width: 100%;
  height: auto;
  display: block; /* Removes any default margins */
  border-radius: 0; /* Remove if you do not want rounded corners on the image */
}

/* src/static/CSS/LandingPageHeader.css */

.landing-page {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 100%;
  margin: 0; 
  padding: 0; 
  margin-top: -30px;
}

.contact-page {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 100%;
  margin: 0; 
  padding: 0; 
  margin-top: -30px;
}

.landing-page-header-image {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 30px;
  border-radius: 8px; /* Adds rounded corners to the image */
}

.welcome-section {
  text-align: center; 
  padding: 20px; /* Adds padding around the section */
}

.welcome-section h1 {
  color: #2c3e50;
  font-size: 2.5rem; /* Adjust the font size if needed */
  margin-bottom: 10px;
  margin-left: 10px; /* Adjusts the title to the left */
}

.welcome-section .section-content {
  display: flex;
  flex-direction: column;
  text-align: center; 
  padding-left: 10px; /* Brings the text to the right slightly */
}

.welcome-section .section-content p {
  font-size: 1.1rem;
  color: #34495e;
  text-align: center; 
  margin-left: -10px; /* Adjusts the paragraph to the left */
}

.about-section,
.features-section,
.contact-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px; /* Adds rounded corners to the sections */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adds a subtle shadow to the sections */
  margin-bottom: 20px;
}

.about-section h2,
.features-section h2,
.contact-section h2 {
  color: #2980b9;
  font-size: 2rem;
  margin-bottom: 15px;
}

.about-section p,
.features-section p,
.contact-section p {
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

a {
  color: #2980b9;
  text-decoration: none; /* Removes underline from links */
}

a:hover {
  text-decoration: underline; /* Adds underline on hover for links */
}

.landing-page-footer {
  background-color: #2c3e50;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.landing-page-footer p {
  margin: 0;
  font-size: 0.9rem;
}

.landing-page-footer a {
  color: #fff;
  text-decoration: none; /* Removes underline from links */
}

.landing-page-footer a:hover {
  text-decoration: underline; /* Adds underline on hover for links */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .landing-page {
    padding: 10px;
  }

  .welcome-section h1 {
    font-size: 2rem;
  }

  .about-section h2,
  .features-section h2,
  .contact-section h2 {
    font-size: 1.5rem;
  }
}
/* Additional CSS styling for images */

.pt-logo {
  width: 150px; /* Adjust as necessary */
  height: auto;
  display: block;
  border-radius: 8px;
  margin: 0 auto 20px; /* Center the logo with automatic horizontal margins */
}

.pt-woodart,
.pt-art,
.pt-tech {
  width: 100px; /* Adjust as necessary */
  height: auto;
  display: block;
  margin: 10px auto;
  border-radius: 8px;
}

.pt-tech-white {
  width: 80px; /* Adjust as necessary */
  height: auto;
  display: block;
  margin: 10px auto; /* Center image in the footer */
}

/* You can add more specific styles if you want to adjust the positioning, add borders, shadows, etc. */
