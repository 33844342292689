:root {
    --clr: #0ab3ff;
  }

  .Form-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    width: 100%; /* Add this line to make the form take the full width */
    max-width: 600px; /* Adjust this value to set the maximum width of the form */
    margin: 0 auto; /* Add this line to center the form horizontally */
  }

  .table-list {
    width: 100%;
    max-width: 800px; /* Adjust this value to set the maximum width of the table */
    margin: 0 auto; /* Add this line to center the table horizontally */
    overflow-x: auto; /* Add this line to enable horizontal scrolling if the table is wider than its container */
  }

  .Form-card-body {
    background: #ffffff; 
    padding: 10px 20px 10px;
    text-align: center;
    display: flex;
    border-radius: 10px;
  }

  .Form-form-row-button-container {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between; /* This spaces the buttons evenly */
    gap: 0px; /* This adds space between the buttons */
  }

  .Form-Title {
    color: #000; 
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.25em;
    margin-bottom: 30px;
  }

  .Form-form-row {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  .Form-input-field {
    position: relative;
  }
  .Form-input-field input {
    width: 100%;
    padding: 10px 0 5px 0;
    background: none;
    border: none;
    border-bottom: 2px solid #999;
    box-shadow: none; 
    outline: none;
    color: #000; 
    font-size: 0.75em;
    letter-spacing: 0.02em;
  }
  .Form-input-field label {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    color: #999;
  }
  .Form-input-field label span {
    display: inline-block;
    transition: 0.2s ease-in-out, font-size 0.2s ease-in-out;
  }
  .Form-input-field input:focus ~ label span,
  .Form-input-field input:valid ~ label span {
    transform: translateY(-25px);
    font-size: .75em;
    color: var(--clr);
    letter-spacing: 0.15em;
  }
  .Form-input-field input:focus,
  .Form-input-field input:valid {
    border-bottom: 2px solid var(--clr);
  }

  .textarea-container {
    width: 100%;
  }
  
  .textarea-label {
    font-size: 14px;
    color: darkgrey;
    display: block;
  }
  
  .textarea-box {
    width: 80%;
    resize: none;
  }
  