body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.header {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 10px;
  font-family: 'Arial', sans-serif;
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 30px;
}

.header-title {
  position: absolute; /* Position the title absolutely */
  left: 50%; /* Move to 50% from the left */
  transform: translateX(-50%); /* Adjust back to center */
}

.hamburger-menu {
  cursor: pointer;
  width: 24px;
  height: 16px;
  position: relative;
}

.line {
  background-color: white;
  height: 2px;
  width: 100%;
  position: absolute;
  transition: all 0.3s;
}

.line:nth-child(1) {
  top: 0;
}

.line:nth-child(2) {
  top: 7px;
}

.line:nth-child(3) {
  top: 14px;
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 50px;
  width: 200px;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  background-color: #333;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.3s;
  z-index: 10;
  overflow-x: hidden;
  text-align: left !important; 

}

.sidebar-bottom.open {
  transform: translateX(0%);
  text-align: left !important; 

}

.sidebar-bottom ul {
  list-style-type: none;
  margin-top: 30px;
  padding: 0;
}

.sidebar-bottom li {
  padding: 12px 8px;
}

.sidebar-bottom a {
  color: white;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  padding: 8px 16px;
  margin: -8px -16px;
  border-radius: 4px;
}

.sidebar-bottom a:hover {
  background-color: #444;
  border-radius: 40px;
}

.main-content {
  margin-top: 30px;
  transition: margin-left 0.3s;
}

.main-content.sidebar-open {
  margin-left: 200px;
}

.header-nav a {
  color: white;
  text-decoration: none;
  padding: 10px 10px; 
  transition: color 0.3s, background-color 0.3s; 
  padding-left: 10px;
  margin: -10px 0; 
  border-radius: 4px; 
}

.header-nav a:hover {
  color: #ccc;
  background-color: rgba(255, 255, 255, 0.1); 
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  max-height: 80%;
  overflow: auto;
}

.user-details {
  padding: 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.user-info {
  cursor: pointer;
  position: relative;
}

.user-info:hover .user-details {
  display: block;
}

.user-info .user-details {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
}

.header-nav {
  display: none; /* Hide header navigation on smaller screens */
}

.header-nav a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  transition: color 0.3s, background-color 0.3s;
}

.header-nav a:hover {
  color: #ccc;
  background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 769px) {
  .header-nav {
    display: flex; /* Show header navigation on larger screens */
  }
}

/* Additional styles for User-button */

.User-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}

.User-button:hover {
  color: #ccc;
  background-color: rgba(255, 255, 255, 0.1);
}

.User-button:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
