.cpni-container {
  display: flex;
  justify-content: center; /* Center the children horizontally */
  align-items: stretch; /* Stretch children to match the tallest item's height */
  gap: 0px; /* Ensure no gap between the children for a cohesive look */
  width: 100%;
  max-width: 620px; /* Adjust based on your layout needs */
  margin: 20px auto;
  flex-direction: row-reverse; /* Adjust if you want the detail box to appear on a specific side */
  background-color: #f9f9f9; /* Background color for the entire container */
  border: 1px solid #ddd; /* Border around the entire container */
  border-radius: 10px; /* Rounded corners for the container */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for the entire container */
}

.cpni-medicine-card, .cpni-detail-box {
  flex: 1; /* Allow both children to take up equal space */
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 0px; /* Remove individual border radius to blend with the container */
}

.cpni-detail-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: auto;
  max-width: 300px;
  min-width: 300px;
  box-sizing: border-box;
  align-self: center;
  background-color: #ffffff;
}

.cpni-medicine-header, .cpni-detail-box {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 20px;
}

.cpni-medicine-item, .cpni-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cpni-label {
  font-weight: bold;
}

.cpni-value {
  font-weight: normal;
}

.cpni-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.cpni-button:not(:first-child) {
  margin-left: 10px;
}
