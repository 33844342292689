.user-display {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px; /* Adjust the gap below as needed */
  }
  
  .user-display label {
    margin-right: 10px; /* Adjust space between the label and the name as needed */
  }

  .Form-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    padding-top: 7px;
    padding-left: 30px;
    max-width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .Form-card-body {
    text-align: left;
  }

  .textarea-box {
    width: max-content;
  }
