.stock-check-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stock-check-search-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.stock-check-search-label {
  font-weight: bold;
  margin-right: 10px;
}

.stock-check-random-button {
  margin-left: 10px;
}

.stock-check-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.stock-check-card {
  background-color: #e7f5f8;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.667);
  padding: 1rem;
  max-width: 350px;
  width: 100%;
  margin: 10px auto;
}

.stock-check-card-header {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stock-check-card-body {
  display: flex;
  flex-direction: column;
}

.stock-check-card-item {
  display: flex;
  gap: 50px;
  align-items: center;
  margin-bottom: 0.25rem;
}

.stock-check-card-label {
  min-width: 120px;
}

.stock-check-card-value {
  color: #333333;
}

.stock-check-bordered {
  border: 1px solid #007bff;
  padding: 5px;
  border-radius: 5px;
  display: flex; /* Add this line */
  align-items: center;
}

.stock-check-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

input[type="number"] {
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box; /* Add this line */
}

.stock-check-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box; /* Add this line */
}


.stock-check-button:hover {
  background-color: #0056b3;
}
