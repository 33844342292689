/* Medicine upload */

.medicine-upload-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 300px;
    margin: 20px auto;
    position: relative;
  }
  
  .medicine-upload-header {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .medicine-upload-header2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1px;
  }
  
  .medicine-upload-body {
    width: 100%;
  }
  
  .medicine-upload-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .medicine-upload-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .medicine-upload-label {
    font-weight: bold;
  }
  
  .medicine-upload-value {
    font-weight: normal;
  }
  
  .medicine-upload-error {
    color: red;
  }
  
  .medicine-upload-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }
  
  .switch-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px; /* This adds some padding around the container */
    box-sizing: border-box; /* This makes sure the padding is included in the width and height */
  }
  
  
  .highlighted-header {
    font-size: 1.5em; /* Increase the font size */
    font-weight: bold; /* Make the text bold */
    padding: 10px; /* Add some padding to create space around the text */
    border: 2px solid #007bff; /* Add a border around the header */
    border-radius: 5px; /* Round the corners of the border */
    margin-bottom: 20px; /* Add some margin below the header for spacing */
    background-color: #f2f2f2; /* Add a light background color */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a slight shadow for depth */
    max-width: 500px;
  }
  
  .settings-container {
    display: flex;
  }
  
  .account-card-error { color: red; }


  .carrier-pack-layout {
    display: flex;
    flex-direction: row; /* Aligns children side by side */
    justify-content: space-between; /* Spaces out children */
    align-items: flex-start; /* Aligns items at the top */
  }

  .product-details-card {
    flex: 1; /* Allows the details card to grow and fill the space */

  }