/* SimpleModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(255, 0, 0, 0.5); /* Red shadow */
    width: 90%;
    max-width: 500px; /* Adjust based on your content size */
    font-family: Arial, sans-serif;
    position: relative;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  button {
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: Arial, sans-serif;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  /* Close button styling if you add one */
  .close-button {
    position: absolute;
    right: 15px;
    top: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  