.visualization-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px; /* Reduced padding */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 70%; /* Adjusted width */
    max-width: 350px; /* Reduced max width for smaller size */
    margin: 20px auto;
    position: relative;
}

.visualization-header {
    font-size: 1.2em; /* Smaller font size */
    font-weight: bold;
    margin-bottom: 15px; /* Reduced margin */
    color: #333;
}

.visualization-body {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pie-chart-container {
    width: 80%; /* Reduced width */
    max-height: 250px; /* Set a max height to control the size */
}
