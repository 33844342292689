
  /* ---------------- EditableTable.css ---------------------- */

  .EditableTable {
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.8em;
  }
  
  .EditableTable th {
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    color: black;
    text-align: center; 
    resize: horizontal; /* Allow column resizing */
    overflow: auto; /* Required for column resizing to work */
  }
  
  .EditableTable .columnHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;

  }
  
  .EditableTable td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .EditableTable .editable-table-button {
    padding: 2px 5px;     
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 0.8em;
    background: none;
    border: none;
    cursor: pointer;
    color: black;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
    font-weight: bold; 
  }
  
  .EditableTable .editable-table-button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .EditableTable .removeButton {
    position: absolute;
    top: 2px;
    left: 2px;
    background: none;
    border: none;
    cursor: pointer;
    color: black;
    box-shadow: none;
    padding: 5px 5px;
    margin-bottom: 1px;
    transition: box-shadow 0.3s ease;
  }
  
  .EditableTable .removeButton:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .EditableTable .sortButton {
    display: block;
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    color: black;
  }
  
  .selectedRow {
    background-color: #dddddd5c; 
  }
  
  /* Scrollbar styles */
  .EditableTable::-webkit-scrollbar {
    width: 10px;
  }
  
  .EditableTable::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .EditableTable::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .EditableTable::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .EditableTable .sortButton {
    font-size: 12px;;  /* Increase the font size of column headers */
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .EditableTable .sortButton::before,
  .EditableTable .sortButton::after {
    color: red;  /* Set the color of up and down arrows to red */
  }
  
  .EditableTable .sortButton[data-direction="asc"]::after {
    content: "↑";  /* Add up arrow when data-direction attribute is 'asc' */
    font-weight: bold;
  }
  
  .EditableTable .sortButton[data-direction="desc"]::after {
    content: "↓";  /* Add down arrow when data-direction attribute is 'desc' */
    font-weight: bold;
  }
  
  /* -------------------Transfer List---------------------------- */
  
  
  .transfer-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .list {
    width: 200px;
    height: 300px;
    border: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .list-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .list-item:hover {
    background-color: #ddd;
  }
  
  .transfer-list-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .transfer-list-button {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.8em;
    background: none;
    border: none;
    color: black;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
  }
  
  .transfer-list-button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .column-selector-container {
    display: flex;
    justify-content: flex-start; /* Align items to the start of the flex container */
    align-items: center; /* Align items vertically in the center */
    max-width: 500px; /* Maximum width for the container */
    margin: 0 auto; /* Center the container horizontally */
    padding: 10px; /* Add some padding around the container */
  }
  
  .select-container {
    display: inline-block; /* Keeps it inline */
    max-width: 300px; /* Maximum width for the select container */
    margin-left: 10px; /* Left margin to separate it from other elements */
  }


  th {
    position: relative;  /* Relative positioning on the parent th element */
  }
  
  .removeButton.bottom-left {
    position: absolute;  /* Absolute positioning on the delete button */
    bottom: 0;  /* Position at the bottom of the parent th element */
    left: 0;  /* Position at the left of the parent th element */
  }

  /* Existing styles */

.settings-box {
  border: 1px solid grey;
  padding: 10px;
  margin-right: 10px;
  max-width: 200px;
  border-radius: 10px;
}

.settings-button {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

/* Add to your CSS */
th:hover .editable-table-button,
th:hover .removeButton {
  visibility: visible;
}

.editable-table-button,
.removeButton {
  visibility: hidden;
}

/* Styles for the top controls wrapper */
.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Styles for the pagination controls wrapper */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Style for the inner pagination control, centering the text and buttons */
.pagination-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for individual pagination control items */
.pagination-item {
  margin: 0 5px;
}
.editable-table-container {
  width: 95%; /* Adjust as needed to fit your layout */
  margin: 0 auto;
}

.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
}

.global-search-container,
.show-entries-container,
.search-toggle-container {
  display: flex;
  align-items: center;
}

.entries-select {
  margin: 0 5px;
  font-size: 12px;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto; /* Add horizontal scrolling if needed */
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  font-size: 12px;
}

.pagination-inner {
  display: flex;
  justify-content: center;
}

.page-item {
  margin: 0 5px;
  cursor: pointer;
  font-weight: normal;
}

.page-item.active,
.page-item:hover {
  font-weight: bold;
  text-decoration: underline;
}

.page-item[disabled] {
  color: grey;
  cursor: not-allowed;
}
