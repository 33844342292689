

.charts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 10px; /* 30px vertical gap, 20px horizontal gap */
}

.visualization-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 800px;
  height: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width and height */
}

.visualization-header {
  font-size: 1.5em;
  margin-bottom: 10px;
}


.pie-chart-container {
  width: 100%;
  height: 100%;
}