.packing-app {
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.packing-main-content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

.packing-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%; /* Adjust width for mobile */
  max-width: 300px; /* Limit maximum width for larger screens */
  margin: 0 auto; /* Center the container */
  position: relative; /* Add this line */
}

.packing-input-container::before {
  font-size: 3em;
  margin-bottom: 20px; /* Reduced distance between picture and title */
  position: absolute; /* Add this line */
  top: -10px; /* Add this line */
  left: 50%; /* Add this line */
  transform: translateX(-50%); /* Add this line */
}

.packing-input-container h2 {
  margin-top: 0;        /* Removes the top margin */
  margin-bottom: 20px;
}

.packing-input-container form {
  width: 100%;
}

.packing-input-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.packing-input-container button:hover {
  background-color: #13c0f9;
}

.packing-switch-button {
  margin-top: 2.5em;
  margin-bottom: 2em;
  background-color: #007bff;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.packing-switch-button:hover {
  background-color: #13c0f9;
}

.packing-switch-button:active {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(2px);
}



/* ------------------------ Display info and Pack--------------------- */


.packing-pack-button-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%; /* Adjust the width to fit mobile screens */
  max-width: 300px; /* Limit the width on larger screens */

  margin: 0 auto; /* Center the card on the page */
  margin-top: 2em;
}

.packing-pack-button-check h2 {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.packing-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2em;
}

.packing-card-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0;
}

.packing-card-label {
  font-size: 1em;
  color: #333;
}

.packing-card-value {
  font-size: 1em;
  color: #666;
  text-align: right;
  flex: 1;
}

.packing-edit-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1.2em;
}

.packing-pack-button-check button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.packing-pack-button-check button:hover {
  background-color: #13c0f9;
}

.medicine-return-form-group-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.medicine-return-label {
  margin-right: 1rem; /* Space between label and input */
  flex-basis: 40%; /* Adjust this value as needed */
  text-align: left;
}

.medicine-return-input.inline-input {
  flex-grow: 1; /* Input field takes up remaining space */
  max-width: 50%; /* Adjust this value as needed */
}

.medicine-return-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.medicine-return-form-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}


.medicine-return-value {
  margin-right: 1rem; /* Space between label and input */
  flex-basis: 70%; /* Adjust this value as needed */
  text-align: left;
}

.button-group {
  display: flex;
  justify-content: center; /* Center the buttons */
  align-items: center;
  margin-top: 10px; /* Space above the buttons */
}

.toggle-button-small, .card-button {
  margin-right: 10px; /* Space between buttons */
}

.toggle-button-container {
  margin-right: 10px; /* Adjust as needed for spacing between the button and title */
}

.toggle-button-small {
  padding: 5px 10px;
  font-size: 0.6em;
}