  /* Button row to align buttons next to each other */
  .button-row {
    display: flex;
    justify-content: center; /* Adjusted to space-around for even spacing */
    gap: 10px; /* Maintain gap for visual separation */
    flex-wrap: wrap; /* Allow buttons to wrap if not enough space */
    padding-left: 10px; /* Adjust as needed to reduce the gap on the left */
    padding-right: 10px;
    margin: 0px;
  }

  /* Submit button - Green */
  .submit-button {
    background-color: #19c842;
    color: black;
    margin-left: 0 !important;
  }

  /* Refresh button - Blue */
  .refresh-button {
    background-color: #007bff;
  }

  /* Change location button - Purple */
  .change-location-button {
    background-color: #6f42c1;
  }

  /* Hover effect for all buttons */
  .medicine-upload-button:hover {
    background-color: #00fe08;
    color: black;
    font-weight: bolder;

  }