
  .page-title {
    color: #4a4a4a;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
  
  .description {
    color: #6a6a6a;
    font-size: 1em;
    text-align: center;
  }
  
  .search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;

  }
  
  input.search-input {
    width: 60%;
    padding: 10px;
    margin-bottom: 0px !important;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
 
  .search-button {
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .report-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }
  
  .report-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .report-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  

  
.boxed-link {
  display: block;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s;
}

.report-box {
  max-width: 600px; /* Adjust this value based on your design preference */
  margin: 0 auto; /* This will center the box horizontally */
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.boxed-link:hover {
  background-color: #f5f5f5;
}

.highlighted .boxed-link {
  background-color: #0ab3ff;
}