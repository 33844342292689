.row-overfilled {
    color: red;
  }
  
  .row-zero {
    color: green;
  }

  .status-text {
    display: inline-block; /* Makes the div inline so it can sit next to the button */
    padding: 10px; /* Adds some padding inside the div */
    color: white; /* Sets the text color to white */
    border-radius: 5px; /* Optional: Rounds the corners of the div */
    margin-right: 10px; /* Adds some space between this div and the button */
    vertical-align: middle; /* Aligns the div vertically with the button */
  }
  
  .text-green {
    background-color: #28a745; /* Green background */
  }
  
  .text-orange {
    background-color: #fd7e14; /* Orange background */
  }
  