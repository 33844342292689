.user-details {
  font-family: Arial, sans-serif;
  margin: 1rem auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

.user-detail {
  display: flex;
  justify-content: space-between; 
  align-items: left;
  border-bottom: 1px solid #ccc; 
}

.user-detail p {
  text-align: left;
  flex: 1; 
  min-width: 200px;
  margin-right: 1rem;

}

.user-title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 2px solid #ccc;
}

a.button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 8px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
  flex: 1 0 auto;
  vertical-align: middle;
  box-sizing: border-box;
  display: inline-block;
  line-height: normal;  
}