.dropdown-container {
  position: relative;
  border-bottom: 2px solid grey;
  border-radius: 4px;
  overflow: visible; /* Changed this to hidden */
  margin: 10px 0;
}

.styled-dropdown {
  appearance: none;
  background-color: transparent;
  font-size: 16px;
  padding: 6px 24px 6px 8px;
  border: none;
  width: 100%;
  outline: none;
  color: grey;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path fill="grey" d="M0 0h10L5 5z"/></svg>'); /* Adding custom arrow */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust position of custom arrow */
}

.dropdown-container:focus-within {
  border-bottom: 2px solid #0ab3ff;
}

.dropdown-label {
  position: absolute;
  top: 6px; /* Adjust top position slightly */
  left: 8px;
  transition: 0.2s ease;
  font-size: 16px;
  pointer-events: none;
  color: grey;
}

.dropdown-label.active {
  font-size: 12px;
  top: -10px; /* Adjust top position slightly */
  left: 4px;
  background-color: transparent;
  padding: 0 4px;
  color: #0ab3ff;
}

.styled-dropdown::-ms-expand {
  display: none;
}
