/* NotPresentReasons.css */

.not-present-reasons {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  .options-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .options-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .add-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .option-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-button {
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .role-permissions-page {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .add-role {
    margin-bottom: 20px;
  }
  
  .permissions-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .permissions-table th, .permissions-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .permissions-table th {
    background-color: #f3f3f3;
  }
  
  .permissions-table td input[type="checkbox"] {
    margin: 0;
  }
  