.medicine-absent-container {
    width: 80%;
    margin: 0 auto;
  }
  
  .medicine-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    transition: max-height 1s ease-in-out;
    overflow: hidden;
  }
  
  .medicine-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center; /* Centered the title */
  }
  
  .medicine-title:hover {
    color: #007bff;
  }
  
  .medicine-batch {
    text-align: center; /* Centered the batch number */
    margin-bottom: 10px;
    border: 2px solid #007bff; /* Blue border */
    font-weight: bold; /* Bold text */
    padding: 10px; /* Some padding */
    border-radius: 5px; /* Rounded corners */
    max-width: fit-content;
  }
  
  
  @keyframes slideDown {
    0% { max-height: 0; }
    100% { max-height: 500px; }
  }
  
  .medicine-form {
    max-height: 0;
    overflow: hidden;
    animation-name: slideDown;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }
  
  .medicine-item.selected .medicine-form {
    animation-play-state: running;
  }
  
  .medicine-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .medicine-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .medicine-actions select,
  .medicine-actions input[type="number"] {
    margin-right: 10px;

  }
  
  .medicine-comment {
    margin-bottom: 10px;
  }
  
  .medicine-comment input[type="text"] {
    height: 50px;
  }
  
  .medicine-submit {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  
  .Pouch-Number {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid #007bff;
  }
  