.Page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin-top: -30px;
  background: linear-gradient(to bottom right, rgb(172, 0, 172), darkblue); /* Gradient background */
}

.login-form-container {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 15px;
  width: 350px;
  height: fit-content;
  margin: auto;
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.357);
  border: 4px solid black;
}

.login-form {
  display: flex;
  flex-direction: column;
  color: rgb(255, 255, 255);
}

.login-form h2,
.login-form label {
  font-weight: bold;
}

.error {
  color: red;
  margin-bottom: 1rem;
}

.login-form button {
  background-color: black;
  color: white;
  border: 2px solid darkgreen; /* Dark green border */
  box-shadow: 0 0 10px darkgreen; /* Dark green shadow */
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  width: 100px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s, text-decoration 0.3s;
  margin-top: 10px;
  text-decoration: none; /* Ensure text is not underlined by default */
}

.login-form button:hover {
  border-color: rgb(68, 144, 68); /* Light green border on hover */
  box-shadow: 0 0 10px rgb(0, 255, 0); /* Light green shadow on hover */
  text-decoration: underline; /* Underline text on hover */
}


.form-links {
  display: flex;

  margin-top: 1rem;
}

.form-links a {
  color: white;
  text-decoration: none;
  margin-right: 10px;
  font-size: smaller;
}

.form-links a:hover {
  text-decoration: underline;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  /* Add more styling as needed */
}
