/* ------------------------Tabs------------------- */

.tabs, .sub-tabs {
  display: flex;
  flex-wrap: wrap; /* Allow the tabs to wrap to a new line if necessary */
  list-style-type: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
}

.tabs {
  justify-content: space-around;
}

.sub-tabs {
  justify-content: center;
}

.tab, .sub-tab {
  cursor: pointer;
  padding: 5px 15px;
  flex-grow: 1; /* Allow the tabs to grow to fill the available space */
  text-align: center; /* Center the text within the tabs */
}

.sub-tab {
  padding: 3px 5px;
}

.tab:hover, .sub-tab:hover {
  background-color: #ddd;
}

.tab.active, .sub-tab.active {
  border-bottom: 2px solid #3f51b5;
}

/* Apply different styles for screens smaller than 600px */
@media (max-width: 600px) {
  .tab, .sub-tab {
    padding: 5px 10px; /* Reduce the padding on smaller screens */
    font-size: 0.8em; /* Reduce the font size on smaller screens */
  }
}


