body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    transition: margin-left 0.3s;
  }
  

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #ccc; /* A border for the container */
    padding: 20px;
    border-radius: 10px; /* Rounded corners for the container */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* A slight shadow for depth */
    margin-top: 20px; /* Space at the top for separation */
    min-width: 300px; /* Optional max width */
    margin: 20px auto; /* Center the container horizontally */
  }

  
  
  .card {
    background-color: #ffffff00;
    border-radius: 5px;
    border: #1c2630;
    padding: 1rem;
    max-width: 500px;
    margin: 10px auto;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
  }
  
  .text-center {
    text-align: center;
  }
  
  .medicine-upload-form .input-group {
    display: flex;
    align-items: center;
  }
  
  .medicine-upload-form .medicine-upload-label {
    flex: 1 0 30%; /* This gives the label 30% of the space */
  }
  
  .medicine-upload-form .medicine-upload-input {
    flex: 1 0 70%; /* This gives the input the rest of the space */
    max-width: 70%;
  }
  
  .medicine-upload-form button[type="submit"] {
    width: 100%;
    max-width: 70%;
    margin-left: 30%; /* To align with the inputs */
  }


  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .Title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .input-field {
    display: flex;
    flex-direction: column;
  }
  
  .input-field input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  
  .account-card-item {
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }
  
  .account-card-label {
    font-weight: bold;
    min-width: 150px;
    margin-bottom: 40px;
  }
  
  .account-card-value {
    color: #333333;
  }
  
  .details-container {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
    margin-top: 5px;
  }
  
  .details-content {
    display: none;
  }
  
  .details-content.expanded {
    display: block;
  }

  .medicine-selection {
    display: flex;
    flex-direction: column;
  }
  
  .medicine-search {
    margin-bottom: 10px;
  }
/* -------------------From old CSS ---------------------- */

.form-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  padding-top: 7px;
  padding-left: 30px;
  max-width: 400px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
  max-width: 300px;
}

.input-field {
  flex: 1;
  margin-right: 10px;
}

.input-field:last-child {
  margin-right: 0;
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"],
input[type="password"],
select {
  display: block;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) inset;
}


.table-container {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  max-width: 500px;
  padding-left: 10px;
}

.table-list {
  flex: 1;
  margin-right: 50px;
  margin-left: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ccc;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.medicine-form {
  flex: 1;
}

.Title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 40px;
  text-decoration: underline;
}

.medicine-list {
  flex: 1;
  margin-right: 50px;
  margin-left: 30px;
}


.reset-button-styles {
  background: none;
  padding: 0;
  border: initial;
  border-radius: initial;
  color: initial;
  font-weight: initial;
  font-size: initial;
  text-transform: none;
  letter-spacing: normal;
  cursor: auto;
  transition: none;
  margin: 0;
  box-shadow: none;
}


.Large-title {
  font-size: 2.5em; /* Making the font size larger */
  text-decoration: underline; /* Adding underline to the text */
  font-family: 'Arial', sans-serif; /* Using a smart/elegant font */
  text-align: center; /* Center align text */
}


/*  --------- MEdicine upload popup  ----------- */
.popup {
  position: fixed;
  height: 80vh; /* This makes the container take up the full height of the viewport */
  width: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Make sure the popup appears above other elements */
}
@media screen and (max-width: 480px) {
  .popup {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 90%; /* or any other value that suits your needs */
  }
}


/*--------Button ------- */


.button {
  margin-top: 0.5em;
  margin-bottom: 1em;
  background-color: #007bff;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  max-width: 150px;
  min-width: 75px;
  margin-left: 10px;
  flex: 1 0 auto;
}

.button:hover {
  background-color: #13c0f9;
}

.button:active {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.button:disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
  box-shadow: none;
}



