.pouch-details-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%; /* Adjusts the width to one-third of the parent container */
    margin: 20px auto; /* Centers the card horizontally */
    min-width: 300px;
}

.pouch-details-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
    width: 100%;
    text-align: left;
}

.pouch-detail {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
    display: flex;
    width: 100%;
}

.pouch-detail-key {
    font-weight: bold;
    min-width: 120px; /* Ensures alignment */
}

.pouch-detail-value {
    margin-left: 10px;
    text-align: left;
    flex-grow: 1;
}
