/* HeaderPacking.css */

.packing-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(0, 195, 255); /* Blue background */
    color: white; /* White text */
    border-radius: 20px; /* Rounded edges */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
    padding: 20px;
    margin: 40px auto; /* Centering the container */
    font-family: Arial, sans-serif; /* Arial font */
    max-width: 600px; /* Maximum width */
}

.header-left {
    flex: 1;
    text-align: left; /* Align text to the right */
}

.header-right {
    margin-top: 20px;
    flex: 1;
    text-align: right; /* Align text to the left */
}

.packing-title,
.user-greeting {
    margin-bottom: 10px;
}

.switch-button {
    margin-top: 30px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    background-color: #000dff; /* Bootstrap primary button color */
    margin-bottom: 10px; /* Added margin-bottom for spacing */
}

.packing-detail,
.packing-actions {
    margin-bottom: 10px;
}

.detail-label {
    font-weight: bold;
}

.pack-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #09ff42; /* Bootstrap success button color */
    color: rgb(0, 0, 0);
    align-self: flex-end; /* Align the button to the right */
}
/* HeaderPacking.css */

.packing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0, 195, 255); /* Blue background */
    color: white; /* White text */
    border-radius: 10px; /* Rounded edges */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
    padding: 20px;
    margin: 40px auto; /* Centering the container */
    font-family: Arial, sans-serif; /* Arial font */
    max-width: 600px; /* Maximum width */
}

.detail-value-QA {
    font-size: xx-large; /* Larger font size */
    font-weight: bold; /* Bold font */

}

.detail-label-QA {
    font-size: large; /* Larger font size */
    font-weight: bold; /* Bold font */
}

.detail-header-box-QA {
    box-shadow: 0 0 10px green;
    padding-top: 30px;

    margin-right: 30px;
    margin-bottom: 30px;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    align-self: center;
    text-align: left;
}

.header-left {
    flex: 1;
    text-align: left;
}

.header-right {
    flex: 2;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.packing-title,
.user-greeting {
    margin-bottom: 10px;
}

.switch-button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #0400ff; /* Light green background for contrast */
    color: rgb(255, 255, 255); /* Darker text for visibility */
    margin-bottom: 10px; /* Space after the button */
}

.unpacking-details {
    align-self: stretch;
}

.unpacking-detail {
    margin-bottom: 10px; /* Space between details */
}

.detail-label {
    font-weight: bold;
}

.pack-button,
.missing-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px; /* Space above the buttons */
    margin-right: 5px; /* Space between buttons */
}

.pack-button {
    background-color: #09ff42; /* Bright green for pack button */
    color: rgb(0, 0, 0);
}

.missing-button {
    margin-top: 35px;
    background-color: #ff4141; /* Bright red for missing button */
    color: white;
}


.qa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0, 195, 255); /* Blue background */
    color: white; /* White text */
    border-radius: 10px; /* Rounded edges */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
    padding: 20px;
    margin: 40px auto; /* Centering the container */
    font-family: Arial, sans-serif; /* Arial font */
    max-width: 600px; /* Maximum width */
}

.header-left {
    flex: 1;
    text-align: left;
}

.header-right {
    flex: 2;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.qa-title,
.user-greeting {
    margin-bottom: 10px;
}

.select-pouch-button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #00ff51; /* Light green background for contrast */
    color: black; /* Darker text for visibility */
    margin-bottom: 10px; /* Space after the button */
}

.qa-detail,
.qa-actions {
    margin-bottom: 10px;
}

.detail-label {
    font-weight: bold;
}

.qa-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #09ff42; /* Bright green for QA button */
    color: white;
}

.pouch-button {
    background-color: #21ff28; /* Green background */
    color: rgb(0, 0, 0); /* White text */
    padding: 10px 15px; /* Top and bottom padding 10px, left and right padding 15px */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor changes to pointer when hovering over the button */
    font-size: 1em; /* Font size */
    font-weight: bold; /* Font weight */
    margin: 10px 0; /* Margin 10px top and bottom, 0 left and right */
    transition: background-color 0.3s; /* Smooth background color transition on hover */
  }
  
  .pouch-button:hover {
    background-color: #45a049; /* Darker shade of green when hovered */
  }
  
  .pouch-button:active {
    background-color: #3e8e41; /* Even darker shade of green when active/clicked */
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2); /* Inner shadow for a pressed effect */
  }
  
  .pouch-button:focus {
    outline: none; /* Removes the outline to avoid a box around the button when clicked */
  }



.detail-content-wrapper {
    display: flex;
    justify-content: center; /* Align items horizontally at the center */
    align-items: center; /* Align items vertically at the center */
    gap: 20px; /* Gap between child elements */
    flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
    margin: 20px auto; /* Center the wrapper with automatic margins on the sides */
    max-width: 1200px; /* Adjust this to control the maximum width of the content */
  }
  
  .detail-header-box,
  .unpacking-actions {
    flex: 1; /* Each child takes equal width */
    max-width: 300px;
    min-width: 300px; 
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    padding: 10px; /* Padding inside each box */
    text-align: left; /* Center the text inside each box */
  }
  
  /* Button Styles */

.button-pack {
    background-color: #007bff; /* Blue */
    color: white;
  }
  
  .button-store {
    background-color: #28a745; /* Green */
    color: white;
  }
  
  .button-unpack {
    background-color: #dc3545; /* Red */
    color: white;
  }
  
  .button-select-station {
    background-color: #0cf900; /* Yellow */
    color: black;
  }
  

/* Button Container Style */
.button-container {
    display: grid;
    grid-template-columns: 1fr; /* Single column by default */
    gap: 10px; /* Space between buttons */
    padding: 10px;
    margin: 20px auto; /* Center the container */
    max-width: 50%; /* Maximum width is half of the page width */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 139, 0.5); /* Dark blue shadow */
  }
  
  /* Adjust layout for wider screens */
  @media (min-width: 600px) { /* Adjust this value based on your design */
    .button-pack, .button-store, .button-unpack, .button-select-station 
        {
      grid-template-columns: repeat(3, 2fr); /* Two columns for wider screens */
    }
  }
  
  /* Common Button Style */
  .button-pack, .button-store, .button-unpack, .button-select-station {
    width: 125px; /* Fixed width */
    height: 60px; /* Fixed height */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    text-align: center; /* Center the text */
    display: flex; /* Use flex to center text vertically and horizontally */
    justify-content: center;
    align-items: center;
    text-decoration: none; /* Remove underline from text */    
    display: grid;
    grid-template-columns: 1fr; /* Single column by default */
    gap: 10px; /* Space between buttons */
    padding: 10px;
    margin: 10px auto; /* Center the container */
 
  }
  
  /* Add your existing button color and hover styles here */
  
  
  /* Hover Effects */
  .button-pack:hover {
    background-color: #0056b3; /* Lighter Blue */
    color: black;
  }
  
  .button-store:hover {
    background-color: #218838; /* Lighter Green */
    color: black;
  }
  
  .button-unpack:hover {
    background-color: #c82333; /* Lighter Red */
    color: black;
  }
  
  .button-select-station:hover {
    background-color: #2a7e00; /* Lighter Yellow */
    color: white;
  }
  .packing-container {
    display: flex;
    align-items: stretch; /* Ensures children of the container have equal height */
  }
  
